import moment from "moment";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"; // Import useQueryClient
import { Flex } from "antd/lib";
import { Button, message, Table } from "antd";
import { PageHeader } from "@ant-design/pro-components";
import { PlusOutlined } from "@ant-design/icons";
import request from "../../../libs/request";

const PipelinesListPage = () => {
	const navigate = useNavigate();
	const { orgId } = useParams();
	const queryClient = useQueryClient(); // Initialize useQueryClient to invalidate queries

	const {
		data: pipelines,
		isLoading,
		isError,
	} = useQuery({
		queryKey: ["dashboard", orgId, "pipelines"],
		queryFn: async () => {
			const { data } = await request.get(`/orgs/${orgId}/pipelines`);
			return data.pipelines;
		},
	});

	const { mutate: deletePipeline, isLoading: deleteLoading } = useMutation({
		mutationFn: async (pipelineId) => {
			await request.delete(`/orgs/${orgId}/pipelines/${pipelineId}`);
		},
		onSuccess: () => {
			message.success("Pipeline deleted successfully.");
			queryClient.invalidateQueries(["dashboard"]); // Invalidate and refetch the pipelines query
		},
		onError: (error) => {
			message.error(`Failed to delete pipeline: ${error.message}`);
		},
	});

	return (
		<div className="page">
			<PageHeader
				title="Pipelines"
				onBack={() => navigate(-1)}
				extra={
					<Button
						icon={<PlusOutlined />}
						type="primary"
						onClick={() => navigate("create")}>
						Create New
					</Button>
				}
			/>

			<Table
				loading={isLoading || deleteLoading}
				dataSource={pipelines}
				columns={[
					{ title: "Name", dataIndex: "name" },
					{
						title: "Created At",
						render: (row) =>
							moment(row.createdAt).format("hh:mm:ssA YYYY-MM-DD"),
					},
					{
						title: "Stages",
						render: (row) => row.stages.map((entry) => entry.name).join(" > "),
					},
					{
						title: "Actions",
						render: (row) => (
							<Flex gap={10}>
								<Button
									onClick={() => navigate(`edit/${row.id}`)}
									size="small">
									Edit
								</Button>
								<Button
									danger
									size="small"
									onClick={() => deletePipeline(row.id)}>
									<i className="fa-solid fa-trash"></i>&nbsp;Delete
								</Button>
							</Flex>
						),
					},
				]}
			/>
		</div>
	);
};

export default PipelinesListPage;
