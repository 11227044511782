import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
	Card,
	Button,
	Modal,
	Form,
	Input,
	Table,
	message,
	Popconfirm,
	Select,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useParams } from "react-router-dom";
import { useState } from "react";
import request from "../../../libs/request";

const MembersPage = () => {
	const { orgId } = useParams();
	const queryClient = useQueryClient();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [form] = Form.useForm();
	const [editingRecord, setEditingRecord] = useState(null);

	// Fetch Members
	const members = useQuery({
		queryKey: ["dashboard", orgId, "members"],
		queryFn: async () => {
			const response = await request.get(`/orgs/${orgId}/members`);
			return response;
		},
	});

	// Fetch Roles for Select Options
	const roles = useQuery({
		queryKey: ["dashboard", orgId, "roles"],
		queryFn: async () => {
			const response = await request.get(`/orgs/${orgId}/roles`);
			return response;
		},
	});

	// Create or Update Member Mutation
	const memberMutation = useMutation({
		mutationFn: async (values) => {
			const payload = {
				memberId: values.userId,
				fullName: values.fullName,
				roles: values.roles,
			};

			if (editingRecord) {
				// Update Member
				const { data } = await request.patch(
					`/orgs/${orgId}/members/${editingRecord.userId}`,
					payload
				);
				return data;
			} else {
				// Add New Member
				const { data } = await request.post(`/orgs/${orgId}/members`, payload);
				return data;
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["dashboard", orgId, "members"]);
			message.success(
				editingRecord
					? "Member updated successfully"
					: "Member added successfully"
			);
			form.resetFields();
			setIsModalVisible(false);
			setEditingRecord(null);
		},
		onError: (error) => {
			message.error(`Failed to save member: ${error.message}`);
		},
	});

	// Show Modal for Add/Edit
	const showModal = (record = null) => {
		setEditingRecord(record);
		form.resetFields();
		if (record)
			form.setFieldsValue({
				memberId: record.userId,
				fullName: record.fullName,
				roles: record.roles,
			});
		setIsModalVisible(true);
	};

	// Handle Modal Submit
	const handleSubmit = () => {
		form
			.validateFields()
			.then((values) => memberMutation.mutate(values))
			.catch((error) => console.error("Validation failed:", error));
	};

	// Delete Member Mutation
	const deleteMutation = useMutation({
		mutationFn: async (userId) => {
			await request.delete(`/orgs/${orgId}/members/${userId}`);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["dashboard", orgId, "members"]);
			message.success("Member deleted successfully");
		},
		onError: (error) => {
			message.error(`Failed to delete member: ${error.message}`);
		},
	});

	// Table Columns
	const columns = [
		{
			title: "Member ID",
			dataIndex: "userId",
		},
		{
			title: "Full Name",
			dataIndex: "fullName",
		},
		{
			title: "Actions",
			render: (_, record) => (
				<>
					<EditOutlined
						style={{ color: "blue", marginRight: 16, cursor: "pointer" }}
						onClick={() => showModal(record)}
					/>
					<Popconfirm
						title="Are you sure you want to delete this member?"
						onConfirm={() => deleteMutation.mutate(record.userId)}
						okText="Yes"
						cancelText="No">
						<DeleteOutlined style={{ color: "red", cursor: "pointer" }} />
					</Popconfirm>
				</>
			),
		},
	];

	return (
		<Card
			title="Members"
			extra={
				<Button
					type="primary"
					icon={<PlusOutlined />}
					onClick={() => showModal()}>
					Add Member
				</Button>
			}>
			<Table
				loading={members.isLoading}
				dataSource={members?.data?.data?.members}
				columns={columns}
				rowKey="userId"
			/>

			<Modal
				title={editingRecord ? "Edit Member" : "Add Member"}
				open={isModalVisible}
				onOk={handleSubmit}
				onCancel={() => setIsModalVisible(false)}
				confirmLoading={memberMutation.isLoading}>
				<Form
					form={form}
					layout="vertical"
					name="member_form">
					<Form.Item
						name="memberId"
						label="Member ID"
						rules={[
							{ required: true, message: "Please enter the member ID!" },
						]}>
						<Input placeholder="Enter member ID" />
					</Form.Item>

					<Form.Item
						name="fullName"
						label="Full Name"
						rules={[
							{ required: true, message: "Please enter the full name!" },
						]}>
						<Input placeholder="Enter full name" />
					</Form.Item>

					<Form.Item
						name="roles"
						label="Roles"
						rules={[
							{ required: true, message: "Please select at least one role!" },
						]}>
						<Select
							mode="multiple"
							placeholder="Select roles"
							loading={roles.isLoading}
							allowClear
							style={{ width: "100%" }}>
							{roles?.data?.data?.roles?.map((role) => (
								<Select.Option
									key={role.id}
									value={role.id}>
									{role.name}
								</Select.Option>
							))}
						</Select>
					</Form.Item>
				</Form>
			</Modal>
		</Card>
	);
};

export default MembersPage;
