import {
  ArrowRightOutlined,
  DeleteOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Avatar, Button, Flex, message, Segmented } from "antd";
import { Table } from "antd/lib";
import moment from "moment/moment";
import { useState } from "react";
import { useContext } from "react";
import { useParams } from "react-router-dom";
import request from "../../../../libs/request";
import JobViewContext from "./context";

import _ from "lodash";
import ApplicationViewModal from "./applicationView.modal";

const ApplicationsPage = () => {
  const queryClient = useQueryClient();
  const { orgId, jobId } = useParams();
  const { pipeline } = useContext(JobViewContext);

  const [applicationView, setApplicationView] = useState(null);

  const [activeStage, setActiveStage] = useState(pipeline.stages[0].id);

  // get id of the drop stage
  const dropStageId = _?.find(pipeline.stages, { name: "Drop" })?.id;

  const applicationsFetch = useQuery({
    queryKey: ["dashboard", orgId, "applications", activeStage],
    queryFn: async () => {
      const { data } = await request.get(`/orgs/${orgId}/jobs/${jobId}/apps`, {
        params: {
          stageId: activeStage,
        },
      });
      return data.apps;
    },
    staleTime: 0,
  });
  
  
  const onForward = useMutation({
    mutationFn: async (application) => {
      return request.post(
        `/orgs/${orgId}/jobs/${jobId}/apps/${application.appId}/forward`,
        {
          userId: application.userId,
          uniqueIdentifier: application?.uniqueIdentifier
        },
      );
    },
    onError: () => {
      message.error("Application Forwarding Failed!");
    },
    onSuccess: (respone, application) => {
      queryClient.setQueryData(
        ["dashboard", orgId, "applications", activeStage],
        (oldData) => oldData.filter((app) => app.appId !== application.appId),
      );
      message.success("Application Forwarded!");
    },
  });

  const onDrop = useMutation({
    mutationFn: async (application) => {
      return request.post(
        `/orgs/${orgId}/jobs/${jobId}/apps/${application.appId}/drop`,
        {
          userId: application.userId,
        },
      );
    },
    onError: () => {
      message.error("Application Droping Failed!");
    },
    onSuccess: (respone, application) => {
      queryClient.setQueryData(
        ["dashboard", orgId, "applications", activeStage],
        (oldData) => oldData.filter((app) => app.appId !== application.appId),
      );
      message.success("Application Dropped!");
    },
  });

  return (
    <Flex vertical gap={10}>
      <Segmented
        options={pipeline.stages.map((entry) => {
          return { label: entry.name, value: entry.id };
        })}
        defaultValue={activeStage}
        onChange={setActiveStage}
      />

      <Table
        onRow={console.log}
        columns={[
          {
            title: "Applicant",
            render: (row) => (
              <Flex gap={10} align="center">
                <Avatar>
                  {`${row.form?.firstName?.[0] || ""}${row.form?.lastName?.[0] || ""}`}
                </Avatar>
                <span>
                  {row.form?.firstName} {row.form?.lastName}
                </span>
              </Flex>
            ),
          },
          {
            title: "Submitted At",
            render: (row) =>
              moment(row.createdAt).format("YYYY-MM-DD hh:mm:ssA"),
          },
          {
            title: "Updated At",
            render: (row) =>
              moment(row.updatedAt).format("YYYY-MM-DD hh:mm:ssA"),
          },
          {
            title: "Score",
            dataIndex: "avgScore",
          },
          {
            title: "Actions",
            render: (row) => (
              <Flex gap={10}>
                <Button
                  size="small"
                  icon={<UserOutlined />}
                  onClick={() => setApplicationView(row)}
                >
                  View
                </Button>

                {![dropStageId].includes(row.currentStageId) && (
                  <>
                    <Button
                      size="small"
                      icon={<ArrowRightOutlined />}
                      loading={
                        (onForward.isLoading || onForward.isPending) &&
                        onForward.variables.appId === row.appId
                      }
                      onClick={() => onForward.mutate(row)}
                    >
                      Forward
                    </Button>
                    <Button
                      size="small"
                      danger
                      loading={
                        (onDrop.isLoading || onDrop.isPending) &&
                        onDrop.variables.appId === row.appId
                      }
                      icon={<DeleteOutlined />}
                      onClick={() => onDrop.mutate(row)}
                    >
                      Drop
                    </Button>
                  </>
                )}
              </Flex>
            ),
          },
        ]}
        loading={applicationsFetch.isLoading}
        dataSource={applicationsFetch.data}
      />

      {applicationView !== null && (
        <ApplicationViewModal
          application={applicationView}
          onClose={() => setApplicationView(null)}
        />
      )}
    </Flex>
  );
};

export default ApplicationsPage;
