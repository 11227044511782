import React from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PageHeader } from "@ant-design/pro-components";
import PipelineEditor from "../components/pipelineEditor"; // Ensure this component handles stages correctly
import request from "../../../libs/request";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "antd";
import { useMutation, useQueryClient } from "@tanstack/react-query";

const schema = yup.object({
	name: yup.string().required("Pipeline Name Required!"),
});

const PipelineCreatePage = () => {
	const navigate = useNavigate();
	const pipelineRef = React.useRef(null);
	const { orgId } = useParams();
	const queryClient = useQueryClient();
	// Mutation for updating pipeline
	const createPipeline = useMutation({
		mutationKey:["create-pipeline"],
		mutationFn: async(payload) => {
			const { stages } = pipelineRef.current;
			return await request.post(`/orgs/${orgId}/pipelines`, {
				name: payload.name,
				stages,
			});
		},
		onSuccess: () => {
			toast.success("Pipeline created successfully!");
			navigate(-1);
			queryClient.invalidateQueries({queryKey:["dashboard"]})
		},
		onError: () => {
			toast.error("Failed to create pipeline!");
		},
	});

	const { register, handleSubmit, formState, reset } = useForm({
		resolver: yupResolver(schema),
		// mode: "onChange",
	});

	const onSubmit = (payload) => {
		const toastId = toast.loading("Saving Pipeline...");
		createPipeline.mutate(payload);
	};

	return (
		<div className="page">
			<PageHeader
				onBack={() => navigate(-1)} // Adds back functionality
				title="Create Pipeline" // Title for the page
				extra={
					<>
						<Link
							to="../"
							className="btn btn-sm btn-default">
							Cancel
						</Link>
						&nbsp;
						<Button
							disabled={formState.isSubmitting || !formState.isValid}
							onClick={handleSubmit(onSubmit)}
							type="primary"
							size="small">
							Save
						</Button>
					</>
				}
			/>
			<div className="row">
				<div className="col-md-6 offset-md-3">
					<Form.Group className="mb-2">
						<Form.Control
							placeholder="Pipeline Name"
							{...register("name")} // Handling name field
						/>
						{formState.errors.name && (
							<Form.Text className="text-danger">
								{formState.errors.name.message}
							</Form.Text>
						)}
					</Form.Group>


						<PipelineEditor
							ref={pipelineRef} 
						/>
				</div>
			</div>
		</div>
	);
};

export default PipelineCreatePage;
