import React from "react";
import { Form } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Link, Navigate, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PageHeader } from "@ant-design/pro-components";
import PipelineEditor from "../components/pipelineEditor"; // Ensure this component handles stages correctly
import request from "../../../libs/request";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";

const schema = yup.object({
	name: yup.string().required("Pipeline Name Required!"),
});

const PipelineEditPage = () => {
	const { orgId, pipelineId } = useParams();
	const navigate = useNavigate();
	const pipelineRef = React.useRef(null);

	// Fetch pipeline details
	const {
		data: pipeline,
		isLoading: loadingPipeline,
		error,
	} = useQuery({
		queryKey: ["pipelines", pipelineId],
		queryFn: async () => {
			const { data } = await request.get(
				`/orgs/${orgId}/pipelines/${pipelineId}`
			);
			return data;
		},
		enabled: !!pipelineId,
	});

	// Handle error state
	if (error) {
		return <div>Error loading pipeline data!</div>;
	}

	// Mutation for updating pipeline
	const updatePipeline = useMutation({
		mutationFn: async (payload) => {
			const { stages } = pipelineRef.current;
			await request.put(`/orgs/${orgId}/pipelines/${pipelineId}`, {
				name: payload.name,
				stages,
			});
		},
		onSuccess: () => {
			toast.success("Pipeline updated successfully!");
			navigate(".."); // Relative navigation to the parent route
		},
		onError: () => {
			toast.error("Failed to update pipeline!");
		},
	});

	const { register, handleSubmit, formState, reset } = useForm({
		resolver: yupResolver(schema),
		defaultValues: { name: pipeline?.name || "" }, // Ensure default values are set correctly
		mode: "onChange",
	});

	// Populate form when pipeline data is loaded
	React.useEffect(() => {
		if (pipeline) {
			reset({ name: pipeline.name }); // Reset name correctly
		}
	}, [pipeline, reset]);

	const onSubmit = (payload) => {
		const toastId = toast.loading("Saving Pipeline...");
		updatePipeline.mutate(payload, {
			onSettled: () => {
				toast.dismiss(toastId);
			},
		});
	};

	if (loadingPipeline) {
		return <div>Loading pipeline...</div>;
	}

	return (
		<div className="page">
			<PageHeader
				onBack={() => navigate(-1)} // Adds back functionality
				title="Edit Pipeline" // Title for the page
				extra={
					<>
						<Link
							to="../"
							className="btn btn-sm btn-default">
							Cancel
						</Link>
						&nbsp;
						<Button
							disabled={formState.isSubmitting || !formState.isValid}
							onClick={handleSubmit(onSubmit)}
							type="primary"
							size="small">
							Save
						</Button>
					</>
				}
			/>
			<div className="row">
				<div className="col-md-6 offset-md-3">
					<Form.Group className="mb-2">
						<Form.Control
							placeholder="Pipeline Name"
							{...register("name")} // Handling name field
						/>
						{formState.errors.name && (
							<Form.Text className="text-danger">
								{formState.errors.name.message}
							</Form.Text>
						)}
					</Form.Group>

					{/* Ensure PipelineEditor handles stages properly */}
					{pipeline?.stages && (
						<PipelineEditor
							ref={pipelineRef}
							stages={pipeline.stages} // Pass stages correctly
						/>
					)}
				</div>
			</div>
		</div>
	);
};

export default PipelineEditPage;
