import { PageLoading } from '@ant-design/pro-components';
import { useQuery } from '@tanstack/react-query';
import { Navigate, Outlet } from 'react-router-dom';
import request from '../libs/request';

const Init = () => {
  const init = useQuery({
    queryKey: ['init'],
    queryFn: async () => {
      const { data } = await request.get('/auth/init');
      localStorage.setItem('userId', data?.userId);
      return data;
    },
    enabled: localStorage.getItem('jobapp.auth.token') ? true : false,
  });
  if (init.isLoading) {
    return <PageLoading />;
  }
  return <Outlet />;
};

export default Init;
