import { Form } from "react-bootstrap";
import { UploadOutlined } from "@ant-design/icons";
import {
	Button,
	Form as AntForm,
	Input,
	Select,
	Typography,
	Upload,
	message as AntMessage,
	Radio,
	Checkbox,
} from "antd";

import { Controller, useForm } from "react-hook-form";

import { yupResolver } from "@hookform/resolvers/yup";
import CreatableSelect from "react-select/creatable";
import CITIES_LIST from "../../../data/cities";
import request from "../../../libs/request";
import React from "react";

import * as yup from "yup";
import { toast } from "react-toastify";

import FieldError from "../components/fieldError";
// import { pipelinesFetch } from "../../store/actions/pipelines";
import PipelineEditor from "../components/pipelineEditor";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { Card, Row, Col, Steps } from "antd";
import { useState } from "react";
import { Flex, message } from "antd/lib";
import { PageHeader } from "@ant-design/pro-components";
import DashboardApis from "../apis";

const JobCreatePage = () => {
	const queryClient = useQueryClient();
	const [activeSection, setActiveSection] = useState(0);
	const navigate = useNavigate();
	const [fileList, setFileList] = useState([]);
	const [advertKey,setAdvertKey] = useState(null);
	const { orgId } = useParams();
	const [uniqueIdentifier,setUniqueIdentifier] = useState(false)
	const [formData, setFormData] = React.useState({
		detail: {
			vacancies: 1,
		},
		requirments: {},
		pipeline: {},
	});
	

	const onCreateJob = useMutation({
		mutationFn: async () => {
			const payload = {
				...formData.detail,
				orgId,
				pipeline:formData.pipeline,
				jobAdvertKey:advertKey,
				appUniqueIdentifierType:uniqueIdentifier
			}
			return request.post(`/orgs/${orgId}/jobs`, payload);
		},
		onSuccess: () => {
			message.success("Job posted Succesfully");
			navigate("../jobs");
		},
		onError: () => message.error("Job posting failed"),
	});

	return (
		<div className="page">
			<PageHeader
				title="Post new Job"
				onBack={() => navigate(-1)}
			/>
			<Row gutter={0}>
				<Col md={5}>
					<Card>
						<Steps
							progressDot
							initial={activeSection}
							direction="vertical"
							items={[
								{ key: "detail", title: "Detail" },
								// { key: "requirments", title: "Requirments" },
								{ key: "pipeline", title: "Pipeline" },
								{ key: "review", title: "Review" },
							]}
						/>
					</Card>
				</Col>
				<Col md={19}>
					{activeSection === 0 && (
						<DetailSection
							initialValues={formData.detail}
							onSubmit={(data) => {
								setFormData({ ...formData, detail: data });
								setActiveSection(1);
							}}
							fileList={fileList} setFileList={setFileList}
							setAdvertKey={setAdvertKey}
							setUniqueIdentifier={setUniqueIdentifier}
							uniqueIdentifier={uniqueIdentifier}
						/>
					)}

					{activeSection === 1 && (
						<PipelineSection
							initialVazlues={formData.pipeline}
							onBack={() => setActiveSection(0)}
							onSubmit={(pipeline) => {
								setFormData({ ...formData, pipeline });
								setActiveSection(2);
							}}
						/>
					)}

					{activeSection === 2 && (
						<Card title="Review">
							<Typography.Title>Rview</Typography.Title>

							<Flex
								justify="space-between"
								align="center"
								style={{ marginTop: "1em" }}>
								<Button onClick={() => setActiveSection(1)}>
									<i class="fa-solid fa-chevron-left"></i> Back&nbsp;
								</Button>
								<Button
									type="primary"
									loading={onCreateJob.isPending}
									onClick={onCreateJob.mutate}>
									Post&nbsp;<i class="fa-solid fa-chevron-right"></i>
								</Button>
							</Flex>
						</Card>
					)}
				</Col>
			</Row>
		</div>
	);
};

const DetailSection = ({ initialValues, onSubmit,fileList, setFileList,setAdvertKey ,setUniqueIdentifier,uniqueIdentifier}) => {
	const handleFileChange = (info) => {
		if (info.file.status === "done") {
			AntMessage.success(`${info.file.name} file uploaded successfully`);
		} else if (info.file.status === "error") {
			AntMessage.error(`${info.file.name} file upload failed.`);
		}
		setFileList(info.fileList);
	};

	const options = [
  {
    label: 'Cnic',
    value: 'cnic',
  },
  {
    label: 'Phone',
    value: 'phone',
  },
  {
    label: 'Email',
    value: 'email',
  },
];
	const onGetUrlForImage = useMutation({
		mutationFn: async (Data) => {
			const {data} =  await request.post(`/user/${localStorage.getItem("userId")}/upload-document`, Data);
			return data?.signedUrl
		},
		onSuccess: async(Data) => {
			const {url,fields} = Data;
			setAdvertKey(fields?.Key);
			const formData = new FormData();
			Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
    });
			formData.append('file', fileList[0]);
			const uploadResponse = await fetch(url, {
        	method: 'POST',
        	body: formData,
    });
	 if (uploadResponse.ok) {
        message.success('File uploaded successfully');
    } else {
        message.error('Upload failed', await uploadResponse.text());
    }
		},
		onError: () => message.error("Image adding failed"),
	});
	const beforeUpload = (file) => {
		onGetUrlForImage.mutate({
			extension:file?.type?.split("/")[1],
			useCase: 'JOB_ADVERT'
		})
		
		// const isImage = file.type.startsWith("image/");
		// if (!isImage) {
		// 	AntMessage.error("You can only upload image files!");
		// }
		// return isImage;
	};

	return (
		<Card title="Job Detail">
			<AntForm
				layout="vertical"
				initialValues={initialValues}
				onFinish={(data) => {
					const payload = {
						...data,
						image: fileList[0],
					};
					onSubmit(payload);
				}}
				variant="filled">
				<AntForm.Item
					label="Title"
					name="jobTitle"
					rules={[{ required: true, message: "" }]}>
					<Input />
				</AntForm.Item>
				<AntForm.Item
					label="Description"
					name="description"
					rules={[{ required: true, message: "" }]}>
					<Input.TextArea rows={3} />
				</AntForm.Item>
				<Row gutter={10}>
					<Col md={8}>
						<AntForm.Item
							label="Employment Type"
							name="employmentType"
							rules={[{ required: true, message: "" }]}>
							<Select
								options={[
									{ label: "Part-Time", value: "partTime" },
									{ label: "Contract", value: "contract" },
								]}
							/>
						</AntForm.Item>
					</Col>

					<Col md={8}>
						<AntForm.Item
							label="Location"
							name="location"
							rules={[{ required: true, message: "" }]}>
							<Select
								showSearch={true}
								options={CITIES_LIST.map((entry) => {
									return { label: entry, value: entry };
								})}
							/>
						</AntForm.Item>
					</Col>
					<Col md={8}>
						<AntForm.Item
							label="Vacancies"
							name="vacancies"
							rules={[{ required: true, message: "" }]}>
							<Input
								type="number"
								min={1}
								max={100}
							/>
						</AntForm.Item>
					</Col>

					<Col md={8}>
						<AntForm.Item
							label="Work"
							name="jobType"
							rules={[{ required: true, message: "" }]}>
							<Select
								options={[
									{ label: "On Site", value: "onSite" },
									{ label: "Remote", value: "remote" },
								]}
							/>
						</AntForm.Item>
					</Col>
					<Col md={8}>
						<AntForm.Item
							label="Salary"
							name="salary">
							<Input />
						</AntForm.Item>
					</Col>
				</Row>
				<Row gutter={10}>
					<Col md={8}>
						<AntForm.Item
							label="Publish Date"
							name="publishDate"
							rules={[{ required: true, message: "" }]}>
							<Input type="date" />
						</AntForm.Item>
					</Col>
					<Col md={8}>
						<AntForm.Item
							label="Expiry Date"
							name="expiryDate"
							rules={[{ required: true, message: "" }]}>
							<Input type="date" />
						</AntForm.Item>
					</Col>
				</Row>
				<Row >
					<div style={{
						marginRight:"10px"
					}}>Unique identifier type</div>
						<Radio.Group block options={options} onChange={(e)=>{
							setUniqueIdentifier(e.target.value);
						}} />
				</Row>
				<Row>

					<AntForm.Item
						label="Job Image"
						name="image"
						valuePropName="fileList"
						getValueFromEvent={(e) => e && e.fileList}
						extra="Upload an image related to the job (optional)">
						<Upload
							action="/upload"
							listType="picture"
							fileList={fileList}
							onChange={handleFileChange}
							beforeUpload={beforeUpload}
							maxCount={1}
							accept="image/*">
							<Button icon={<UploadOutlined />}>Upload Image</Button>
						</Upload>
					</AntForm.Item>
				</Row>
				<Flex
					justify="space-between"
					align="center">
					<span />
					<Button
						htmlType="submit"
						type="primary">
						Next&nbsp;<i class="fa-solid fa-chevron-right"></i>
					</Button>
				</Flex>
			</AntForm>
		</Card>
	);
};

const PipelineSection = (props) => {
	const { orgId } = useParams();
	const { data: pipelines, isLoading } = useQuery({
		queryKey: ["dashboard", orgId, "pipelines"],
		queryFn: async () => {
			const { data } = await request.get(`/orgs/${orgId}/pipelines`);
			return data.pipelines || [];
		},
	});

	const [pipelineIndex, setPiplineIndex] = React.useState(null);
	const pipelineRef = React.useRef(null);
	if (isLoading) return <span>Loading....</span>;

	return (
		<Card
			title="Pipeline"
			extra={
				<Select
					style={{ width: "15rem" }}
					onChange={(index) => setPiplineIndex(index)}
					options={pipelines.map((entry, index) => {
						return { label: entry.name, value: index };
					})}
				/>
			}>
			<Flex
				vertical
				justify="center">
				{pipelineIndex == null && (
					<Typography.Title
						level={5}
						style={{ textAlign: "center" }}
						type="secondary">
						Select a Pipeline
					</Typography.Title>
				)}

				{pipelineIndex !== null && (
					<PipelineEditor
						ref={pipelineRef}
						stages={pipelines[pipelineIndex].stages}
					/>
				)}
			</Flex>

			<Flex
				justify="space-between"
				align="center"
				style={{ marginTop: "1em" }}>
				<Button onClick={props.onBack}>
					<i class="fa-solid fa-chevron-left"></i> Back&nbsp;
				</Button>
				<Button
					type="primary"
					onClick={() =>
						props.onSubmit({
							name: pipelines[pipelineIndex].name,
							stages: pipelineRef.current.stages,
						})
					}>
					Next&nbsp;<i class="fa-solid fa-chevron-right"></i>
				</Button>
			</Flex>
		</Card>
	);
};

export default JobCreatePage;
