import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
	Card,
	Button,
	Modal,
	Form,
	Input,
	Table,
	message,
	Checkbox,
	Collapse,
} from "antd";
import { PlusOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useParams } from "react-router-dom";
import request from "../../../libs/request";
import moment from "moment";
import * as yup from "yup";

const { Panel } = Collapse;

// Permission configuration
const permissionsList = [
	{
		title: "Jobs",
		tag: "jobs.*",
		description: "Job Management",
		childs: [
			{ title: "Create", tag: "jobs.create", description: "Post Jobs" },
			{
				title: "Update",
				tag: "jobs.update",
				description: "Update Jobs Detail",
			},
			{ title: "Delete", tag: "jobs.delete", description: "Delete Jobs" },
		],
	},
	{
		title: "Members",
		tag: "members.*",
		description: "Member Management",
		childs: [
			{ title: "View", tag: "members.view", description: "View Members" },
			{ title: "Create", tag: "members.create", description: "Add Members" },
			{ title: "Update", tag: "members.update", description: "Update Members" },
			{ title: "Delete", tag: "members.delete", description: "Delete Members" },
		],
	},
	{
		title: "Roles",
		tag: "roles.*",
		description: "Role Management",
		childs: [
			{ title: "View", tag: "roles.view", description: "View Roles" },
			{ title: "Create", tag: "roles.create", description: "Create Roles" },
			{ title: "Update", tag: "roles.update", description: "Update Roles" },
			{ title: "Delete", tag: "roles.delete", description: "Delete Roles" },
		],
	},
];

// Validation schema
const schema = yup.object({
	name: yup.string().required("Name is required"),
	description: yup.string().required("Description is required"),
	permissions: yup.array().min(1, "At least one permission is required"),
});

const RolesPage = () => {
	const { orgId } = useParams();
	const queryClient = useQueryClient();
	const [isModalVisible, setIsModalVisible] = useState(false);
	const [form] = Form.useForm();
	const [editingRecord, setEditingRecord] = useState(null);
	const [selectedPermissions, setSelectedPermissions] = useState([]);

	// Fetch Roles
	const roles = useQuery({
		queryKey: ["dashboard", orgId, "roles"],
		queryFn: async () => {
			const response = await request.get(`/orgs/${orgId}/roles`);
			return response;
		},
	});

	// Create or Update Role Mutation
	const roleMutation = useMutation({
		mutationFn: async (values) => {
			const payload = {
				...values,
				permissions: selectedPermissions,
			};

			if (editingRecord) {
				const { data } = await request.patch(
					`/orgs/${orgId}/roles/${editingRecord.id}`,
					payload
				);
				return data;
			} else {
				const { data } = await request.post(`/orgs/${orgId}/roles`, payload);
				return data;
			}
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["dashboard", orgId, "roles"]);
			message.success(
				editingRecord ? "Role updated successfully" : "Role added successfully"
			);
			resetForm();
		},
		onError: (error) => {
			message.error(`Failed to save role: ${error.message}`);
		},
	});

	// Delete Role Mutation
	const deleteMutation = useMutation({
		mutationFn: async (roleId) => {
			await request.delete(`/orgs/${orgId}/roles/${roleId}`);
		},
		onSuccess: () => {
			queryClient.invalidateQueries(["dashboard", orgId, "roles"]);
			message.success("Role deleted successfully");
		},
		onError: (error) => {
			message.error(`Failed to delete role: ${error.message}`);
		},
	});

	const resetForm = () => {
		form.resetFields();
		setSelectedPermissions([]);
		setIsModalVisible(false);
		setEditingRecord(null);
	};

	const showModal = (record = null) => {
		setEditingRecord(record);
		form.resetFields();
		if (record) {
			form.setFieldsValue(record);
			setSelectedPermissions(record.permissions || []);
		} else {
			setSelectedPermissions([]);
		}
		setIsModalVisible(true);
	};

	const handleSubmit = async () => {
		try {
			const values = await form.validateFields();
			await schema.validate({ ...values, permissions: selectedPermissions });
			roleMutation.mutate(values);
		} catch (error) {
			if (error.inner) {
				error.inner.forEach((err) => {
					message.error(err.message);
				});
			} else {
				console.error("Validation failed:", error);
			}
		}
	};

	const handlePermissionChange = (tag, checked) => {
		const nextSelectedPermissions = checked
			? [...selectedPermissions, tag]
			: selectedPermissions.filter((t) => t !== tag);
		setSelectedPermissions(nextSelectedPermissions);
	};

	const handleGroupPermissionChange = (groupTag, checked) => {
		const group = permissionsList.find((g) => g.tag === groupTag);
		const childTags = group.childs.map((child) => child.tag);

		const nextSelectedPermissions = checked
			? [...new Set([...selectedPermissions, groupTag, ...childTags])]
			: selectedPermissions.filter(
					(t) => !childTags.includes(t) && t !== groupTag
			  );

		setSelectedPermissions(nextSelectedPermissions);
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
		},
		{
			title: "Description",
			dataIndex: "description",
		},
		{
			title: "Created At",
			dataIndex: "createdAt",
			render: (date) => moment(date).format("YYYY-MM-DD hh:mm A"),
		},
		{
			title: "Actions",
			render: (_, record) => (
				<>
					<EditOutlined
						style={{ color: "blue", marginRight: 16, cursor: "pointer" }}
						onClick={() => showModal(record)}
					/>
					<DeleteOutlined
						style={{ color: "red", cursor: "pointer" }}
						onClick={() => deleteMutation.mutate(record.id)}
					/>
				</>
			),
		},
	];

	return (
		<Card
			title="Roles"
			extra={
				<Button
					type="primary"
					icon={<PlusOutlined />}
					onClick={() => showModal()}>
					Add Role
				</Button>
			}>
			<Table
				loading={roles.isLoading}
				dataSource={roles?.data?.data?.roles}
				columns={columns}
				rowKey="id"
			/>

			<Modal
				title={editingRecord ? "Edit Role" : "Add Role"}
				open={isModalVisible}
				onOk={handleSubmit}
				onCancel={resetForm}
				width={800}
				confirmLoading={roleMutation.isLoading}>
				<Form
					form={form}
					layout="vertical"
					name="role_form">
					<Form.Item
						name="name"
						label="Name"
						rules={[
							{ required: true, message: "Please enter the role name!" },
						]}>
						<Input placeholder="Enter role name" />
					</Form.Item>

					<Form.Item
						name="description"
						label="Description"
						rules={[
							{ required: true, message: "Please enter the description!" },
						]}>
						<Input.TextArea placeholder="Enter role description" />
					</Form.Item>

					<div className="mb-4">
						<label className="font-medium mb-2 block">Permissions</label>
						<Collapse>
							{permissionsList.map((group) => (
								<Panel
									header={
										<div className="flex items-center">
											<Checkbox
												checked={selectedPermissions.includes(group.tag)}
												onChange={(e) =>
													handleGroupPermissionChange(
														group.tag,
														e.target.checked
													)
												}>
												{group.title}
											</Checkbox>
										</div>
									}
									key={group.tag}>
									<div className="pl-6">
										{group.childs.map((permission) => (
											<div
												key={permission.tag}
												className="mb-2">
												<Checkbox
													checked={selectedPermissions.includes(permission.tag)}
													onChange={(e) =>
														handlePermissionChange(
															permission.tag,
															e.target.checked
														)
													}>
													<div>
														<div>{permission.title}</div>
														<div className="text-gray-500 text-sm">
															{permission.description}
														</div>
													</div>
												</Checkbox>
											</div>
										))}
									</div>
								</Panel>
							))}
						</Collapse>
					</div>
				</Form>
			</Modal>
		</Card>
	);
};

export default RolesPage;
