import Card from 'antd/es/card/Card';
import { useEffect } from 'react';
import Configs from '../../configs';
import { Navigate } from 'react-router-dom';

const LoginPage = () => {
  const endpoint = Configs.API_BASE_URL.replace('/v1', '/v2');

  useEffect(() => {
    const container = document.querySelector('.meraid-auth-container');
    if (!container.shadowRoot) {
      window.meraIDAuth({
        container: '.meraid-auth-container',
        buttonStyle: 'border-radius: 12px;width: max-content;height: 40px',
        authApi: {
          method: 'POST',
          url: `${endpoint}/auth/meraid-sign-in`,
        },
        collectApi: {
          method: 'POST',
          url: `${endpoint}/auth/meraid-collect`,
        },
        onSuccess: async ({ completionData, isNewUser }) => {
          localStorage.setItem(
            'jobapp.auth.token',
            completionData.jwt.accessToken
          );
          if (
            completionData?.role === 'admin' ||
            completionData?.roles.includes('admin')
          ) {
            return (window.location.href = '/sudo/organizations');
          }
          if (isNewUser) window.location.href = '/account/profile';
          else window.location.href = '/';
        },
      });
    }
  }, []);

  return (
    <Card>
      <div className='meraid-auth-container' />
    </Card>
  );
};

export default LoginPage;
