import { useMutation, useQuery } from '@tanstack/react-query';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Steps,
} from 'antd';
import request from '../../../libs/request';

import _ from 'lodash';
import { useEffect, useState } from 'react';
import TextArea from 'antd/lib/input/TextArea';
import { Upload } from 'antd/lib';
import { UploadOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { PageLoading } from '@ant-design/pro-components';
import Configs from '../../../configs';
import EductionSection from '../components/form/eduction.section';
import ExperienceSection from '../components/form/experience.section';
import SkillSection from '../components/form/skill.section';

const JobApplySection = () => {
  const [activeSectionIdx, setActiveSectionIdx] = useState(null);

  const { jobId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});

  const template = useQuery({
    queryKey: ['template'],
    queryFn: async () => {
      const { data } = await request.get(`/jobs/${jobId}/form/components`);
      const transformedData = _.groupBy(data, 'section');
      setActiveSectionIdx(0);
      return transformedData;
    },
  });

  const submit = useMutation({
    mutationFn: async (form_data) => {
      const data = {
        ageRelaxationClaimed: true,
        tncAccepted: true,
        declarationAccepted: true,
        form: form_data,
      };
      const endpoint = Configs.API_BASE_URL.replace('/v1', '/v2');
      const response = await request.post(
        `${endpoint}/jobs/${jobId}/apply`,
        data
      );
      console.log('Response:', response);
      return response.data;
    },
    onSuccess: (data) => {
      navigate(`/jobs`);
    },
    onError: (error) => {
      console.error('Submission failed:', error);
    },
  });

  const onNext = (payload) => {
    const activeSection = Object.keys(template.data)[activeSectionIdx];
    const updatedFormData = { ...formData, [activeSection]: payload };
    setFormData(updatedFormData);

    const totalSectionsLength = Object.keys(template?.data ?? []).length;
    const currentIndex = Object.keys(template?.data ?? []).indexOf(
      activeSection
    );
    const nextSection = Object.keys(template?.data ?? [])[currentIndex + 1];

    if (currentIndex + 1 === totalSectionsLength) {
      const flattenedData = {};

      Object.entries(updatedFormData)?.forEach(([sectionKey, sectionData]) => {
        if (sectionKey === 'education') {
          flattenedData[sectionKey] = sectionData.education.map(
            (education) => ({
              degree: education.degree,
              institute: education.institute,
              grade: education.grade,
            })
          );
        } else if (sectionKey === 'experience') {
          flattenedData[sectionKey] = sectionData.professionalExperience.map(
            (experience) => ({
              title: experience.title,
              employmentType: experience.employmentType,
              company: experience.company,
              location: experience.location,
            })
          );
        } else {
          Object.entries(sectionData).forEach(([key, value]) => {
            flattenedData[key] = value;
          });
        }
      });
      submit.mutate(flattenedData);
    } else if (nextSection) {
      setActiveSectionIdx(activeSectionIdx + 1);
    }
  };
  const onPrevious = () => {};

  useEffect(() => {
    if (template?.data) {
      setActiveSectionIdx(0);
    }
  }, [template.data]);

  if (template.isLoading || activeSectionIdx === null) {
    return <PageLoading />;
  }
  const activeSection = Object.keys(template.data)[activeSectionIdx];

  return (
    <div className='container'>
      <Row gutter={10}>
        <Col md={6}>
          <Card>
            <Steps
              progressDot
              initial={0}
              direction='vertical'
              current={activeSectionIdx}
              items={Object.keys(template?.data ?? []).map((entry) => ({
                title: entry,
                key: entry,
              }))}
              style={{ cursor: 'default' }}
            />
          </Card>
        </Col>
        <Col md={18}>
          <Card title={activeSection}>
            <Form
              variant='filled'
              layout='vertical'
              onFinish={onNext}
              initialValues={formData[activeSection] || {}}
            >
              {(template.data[activeSection] || []).map((field) => {
                if (field.type !== 'list' && field.name !== 'education') {
                  return (
                    <Form.Item
                      name={field.name}
                      key={`field-${field.name}`}
                      label={field.label}
                      rules={[
                        {
                          required: field.required || false,
                          message: `${field.label} is required`,
                        },
                      ]}
                    >
                      {/* Render form fields based on field type */}
                      {field.type === 'date' ? (
                        <DatePicker placeholder={field.placeholder} />
                      ) : field.type === 'file' ? (
                        <Upload>
                          <Button icon={<UploadOutlined />}>
                            Click to Upload
                          </Button>
                        </Upload>
                      ) : field.type === 'select' ? (
                        <Select
                          options={field.options}
                          placeholder={field.placeholder}
                        />
                      ) : field.type === 'textarea' ? (
                        <TextArea placeholder={field.placeholder} />
                      ) : (
                        <Input placeholder={field.placeholder} />
                      )}
                    </Form.Item>
                  );
                }
              })}
              {activeSection === 'education' && <EductionSection />}
              {activeSection === 'experience' && <ExperienceSection />}
              {activeSection === 'skills' && <SkillSection />}

              <Flex
                justify='space-between'
                align='center'
              >
                <Button
                  type='default'
                  onClick={onPrevious}
                  disabled={activeSectionIdx == 0}
                >
                  Previous
                </Button>
                <Button
                  type='primary'
                  htmlType='submit'
                >
                  {activeSectionIdx + 1 === Object.keys(template.data).length
                    ? 'Submit'
                    : 'Next'}
                </Button>
              </Flex>
            </Form>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default JobApplySection;
