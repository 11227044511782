import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { PageHeader, PageLoading } from '@ant-design/pro-components';
import {
  Button,
  Table,
  Modal,
  Form,
  Input,
  Select,
  message,
  Popconfirm,
} from 'antd';
import request from '../../libs/request';
import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';
import React, { useState } from 'react';

const { Option } = Select;

const OrganizationsPage = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingRecord, setEditingRecord] = useState(null);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { data, isLoading, isError } = useQuery({
    queryKey: ['sudo', 'organization'],
    queryFn: async () => {
      const { data } = await request.get('/orgs');
      return data;
    },
  });

  const addOrgMutation = useMutation({
    mutationFn: async (values) => {
      if (editingRecord) {
        const { data } = await request.put(
          `/org/${editingRecord?.orgId}`,
          values
        );
        return data;
      } else {
        const { data } = await request.post('/org', values);
        return data;
      }
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['sudo', 'organization']);
      message.success(
        editingRecord
          ? 'Organization updated successfully'
          : 'Organization added successfully'
      );
      setIsModalVisible(false);
      form.resetFields();
      setEditingRecord(null);
    },
    onError: (error) => {
      message.error(`Failed to save organization: ${error.message}`);
    },
  });

  const deleteOrgMutation = useMutation({
    mutationFn: async (id) => {
      await request.delete(`/org/${id}`);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(['sudo', 'organization']);
      message.success('Organization deleted successfully');
    },
    onError: (error) => {
      message.error(`Failed to delete organization: ${error.message}`);
    },
  });

  const showModal = () => {
    form.resetFields();
    setEditingRecord(null);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    form.resetFields();
    setIsModalVisible(false);
    setEditingRecord(null);
  };

  const handleSubmit = () => {
    form
      .validateFields()
      .then((values) => {
        if (editingRecord) {
          const updatedValues = {
            orgName: values.orgName,
            orgAddress: values.orgAddress,
            department: values.department,
            ministry: values.ministry,
            orgType: values.orgType,
            website: values.website,
            logo: values.logo,
          };
          addOrgMutation.mutate(updatedValues);
        } else {
          addOrgMutation.mutate(values);
        }
      })
      .catch((errorInfo) => {
        console.log('Validation failed:', errorInfo);
      });
  };

  const handleEdit = (record) => {
    setEditingRecord(record);
    form.setFieldsValue({
      orgName: record.orgName,
      orgAddress: record.orgAddress,
      department: record.department,
      ministry: record.ministry,
      orgType: record.orgType,
      website: record.website,
      logo: record.logo,
    });
    setIsModalVisible(true);
  };

  const handleDelete = (id) => {
    deleteOrgMutation.mutate(id);
  };

  const columns = [
    {
      title: 'Name',
      dataIndex: 'orgName',
      key: 'orgName',
    },
    {
      title: 'Type',
      dataIndex: 'orgType',
      key: 'orgType',
    },
    {
      title: 'Address',
      dataIndex: 'orgAddress',
      key: 'orgAddress',
    },
    {
      title: 'Department',
      dataIndex: 'department',
      key: 'department',
    },
    {
      title: 'Ministry',
      dataIndex: 'ministry',
      key: 'ministry',
    },
    {
      title: 'Website',
      dataIndex: 'website',
      key: 'website',
      render: (text) => (
        <a
          href={text}
          target='_blank'
          rel='noopener noreferrer'
        >
          {text}
        </a>
      ),
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text, record) => (
        <>
          <EditOutlined
            style={{ color: 'blue', marginRight: 16, cursor: 'pointer' }}
            onClick={() => handleEdit(record)}
          />
          <Popconfirm
            title='Are you sure you want to delete this organization?'
            onConfirm={() => handleDelete(record.orgId)}
            okText='Yes'
            cancelText='No'
          >
            <DeleteOutlined style={{ color: 'red', cursor: 'pointer' }} />
          </Popconfirm>
        </>
      ),
    },
  ];

  if (isLoading) return <PageLoading />;

  return (
    <>
      <PageHeader
        title='Organizations'
        extra={[
          <Button
            key='add-org'
            type='primary'
            icon={<PlusOutlined />}
            onClick={showModal}
          >
            Add New
          </Button>,
        ]}
        style={{
          marginBottom: '20px',
          padding: '16px 32px',
        }}
      />
      <Table
        loading={isLoading}
        dataSource={data?.organisations}
        columns={columns}
        rowKey='id'
        pagination={{ pageSize: 10 }}
        style={{
          margin: '0px 32px',
        }}
      />

      <Modal
        title={editingRecord ? 'Edit Organization' : 'Add New Organization'}
        open={isModalVisible}
        onOk={handleSubmit}
        onCancel={handleCancel}
        confirmLoading={addOrgMutation.isLoading}
      >
        <Form
          form={form}
          layout='vertical'
          name='organization_form'
        >
          <Form.Item
            name='orgName'
            label='Organization Name'
            rules={[
              {
                required: true,
                message: 'Please input the organization name!',
              },
            ]}
          >
            <Input placeholder='Enter organization name' />
          </Form.Item>

          <Form.Item
            name='orgAddress'
            label='Organization Address'
            rules={[
              {
                required: true,
                message: 'Please input the organization address!',
              },
            ]}
          >
            <Input placeholder='Enter organization address' />
          </Form.Item>

          <Form.Item
            name='department'
            label='Department'
            rules={[{ required: true, message: 'Please select a department!' }]}
          >
            <Input placeholder='Enter department name' />
          </Form.Item>

          <Form.Item
            name='ministry'
            label='Ministry'
            rules={[{ required: true, message: 'Please select a ministry!' }]}
          >
            <Input placeholder='Enter ministry name' />
          </Form.Item>

          <Form.Item
            name='orgType'
            label='Organization Type'
            rules={[
              {
                required: true,
                message: 'Please select an organization type!',
              },
            ]}
          >
            <Select placeholder='Select organization type'>
              <Option value='public'>Public</Option>
              <Option value='private'>Private</Option>
              <Option value='ngo'>NGO</Option>
            </Select>
          </Form.Item>

          <Form.Item
            name='website'
            label='Website'
            rules={[
              { required: true, message: 'Please input the website!' },
              { type: 'url', message: 'Please enter a valid URL!' },
            ]}
          >
            <Input placeholder='Enter organization website' />
          </Form.Item>

          <Form.Item
            name='logo'
            label='Logo URL'
          >
            <Input placeholder='Enter logo URL (optional)' />
          </Form.Item>
          {!editingRecord && (
            <Form.Item
              name='phoneNumber'
              label='Phone Number'
              rules={[
                {
                  required: true,
                  message: 'Please input the phone number!',
                },
              ]}
            >
              <Input placeholder='Enter phone number' />
            </Form.Item>
          )}
        </Form>
      </Modal>
    </>
  );
};

export default OrganizationsPage;
