import React from "react";
import { Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactSelect from "react-select";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import request from "../../../libs/request";
import pMap from "p-map";

// Validation schemas
const scoreCardSchema = yup.object({
	title: yup.string().required("Title is required"),
	total: yup.number().required("Total Score is required"),
});

const stageNameSchema = yup.object({
	name: yup.string().required("Stage name is required"),
});

const PipelineEditor = React.forwardRef((props, ref) => {
	const [stages, setStages] = React.useState(props.stages || []);

	// Expose stages to the parent component (used in the mutation)
	React.useImperativeHandle(
		ref,
		() => ({
			stages,
		}),
		[stages]
	);

	const {
		register,
		handleSubmit,
		formState: { errors },
		reset,
	} = useForm({
		resolver: yupResolver(stageNameSchema),
		defaultValues: { name: "" },
	});

	// Add stage
	const onAddStage = ({ name }) => {
		setStages((prevStages) => [
			...prevStages,
			{
				id: Date.now(), // Unique ID for the stage
				name: name,
				scoreCards: [],
				assignees: [],
			},
		]);
		reset(); // Reset input field
	};

	// Remove stage
	const onStageRemove = (index) => {
		setStages((prevStages) => prevStages.filter((_, i) => i !== index));
	};

	// Update stage (name or other fields)
	const onStageUpdate = (stage, index) => {
		setStages((prevStages) =>
			prevStages.map((s, i) => (i === index ? { ...s, ...stage } : s))
		);
	};

	return (
		<div className="pipeline-editor">
			<div className="stages">
				{stages.map((entry, index) => (
					<StageItem
						key={entry.id}
						{...entry}
						onRemove={() => onStageRemove(index)}
						onUpdate={(stage) => onStageUpdate(stage, index)}
					/>
				))}
			</div>

			{/* Form for adding a new stage */}
			<form
				onSubmit={handleSubmit(onAddStage)}
				className="add-stage-form mt-4">
				<div className="form-group">
					<input
						type="text"
						className={`form-control ${errors.name ? "is-invalid" : ""}`}
						placeholder="Enter stage name"
						{...register("name")}
					/>
					{errors.name && (
						<div className="invalid-feedback">{errors.name.message}</div>
					)}
				</div>
				<button
					type="submit"
					className="btn btn-block btn-primary mt-2">
					<i className="fa-solid fa-plus" />
					&nbsp;Add Stage
				</button>
			</form>
		</div>
	);
});

const StageItem = (props) => {
	const { orgId } = useParams();
	const [scoreCardModalVisible, setScoreCardModalVisible] =
		React.useState(false);
	const { register, handleSubmit, reset, formState } = useForm({
		resolver: yupResolver(scoreCardSchema),
	});

	const members = useQuery({
		queryKey: ["dashboard", orgId, "members"],
		queryFn: async () => {
			const { data } = await request.get(`/orgs/${orgId}/members`);
			const members = await pMap(
				data.members,
				async (member) => {
					const { data: profile } = await request.get(`/user/${member.userId}`);
					return { ...member, fullName: profile.fullName };
				},
				{ concurrency: 3 }
			);
			return members;
		},
	});

	const onScoreAdd = (payload) => {
		props.onUpdate({
			scoreCards: [...(props.scoreCards || []), payload],
		});
		setScoreCardModalVisible(false);
		reset();
	};

	const onDeleteScoreCard = (index) => {
		props.onUpdate({
			scoreCards: props.scoreCards.filter((_, i) => i !== index),
		});
	};

	return (
		<div className="stage">
			<div className="header">
				<input
					className="form-control input-sm name"
					value={props.name}
					onChange={({ target }) => props.onUpdate({ name: target.value })}
				/>
				<button
					type="button"
					className="btn btn-default text-danger btn-sm remove-stage-btn"
					onClick={props.onRemove}>
					<i className="fa-solid fa-trash"></i>
				</button>
			</div>
			<div className="body">
				<div className="mt-3">
					<h6 style={{ fontSize: ".9rem" }}>Score Cards:</h6>
					<div className="score-cards-list">
						{(props.scoreCards || []).map((entry, index) => (
							<div
								key={index}
								className="score-card">
								<div className="score">{entry.total}</div>
								<h6 className="title">{entry.title}</h6>
								<button
									type="button"
									className="btn btn-default btn-sm"
									onClick={() => onDeleteScoreCard(index)}>
									<i className="fa-solid fa-xmark"></i>
								</button>
							</div>
						))}
						<button
							type="button"
							className="btn btn-sm"
							onClick={() => setScoreCardModalVisible(true)}>
							<i className="fa-solid fa-plus" />
							&nbsp; Add Score Card
						</button>
					</div>
				</div>

				<h6 style={{ fontSize: ".9rem", marginTop: "1rem" }}>Assignees:</h6>
				<ReactSelect
					onChange={(value) =>
						props.onUpdate({ assignees: value.map((e) => e.value) })
					}
					isLoading={members.isLoading}
					className="react-select-container"
					classNamePrefix="react-select"
					placeholder="Search..."
					isMulti={true}
					options={
						members.data?.map((user) => ({
							value: user.userId,
							label: user.fullName,
						})) || []
					}
				/>
			</div>

			{scoreCardModalVisible && (
				<Form>
					<Modal
						show={true}
						centered
						onHide={() => setScoreCardModalVisible(false)}>
						<Modal.Header closeButton>
							<Modal.Title>New Score Card</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form.Group className="mb-2">
								<Form.Label>Title</Form.Label>
								<Form.Control {...register("title")} />
								{formState.errors.title && (
									<Form.Text className="text-danger">
										{formState.errors.title.message}
									</Form.Text>
								)}
							</Form.Group>
							<Form.Group>
								<Form.Label>Total Score</Form.Label>
								<Form.Control
									type="number"
									{...register("total")}
								/>
								{formState.errors.total && (
									<Form.Text className="text-danger">
										{formState.errors.total.message}
									</Form.Text>
								)}
							</Form.Group>
						</Modal.Body>
						<Modal.Footer>
							<button
								type="button"
								onClick={handleSubmit(onScoreAdd)}
								disabled={!formState.isValid}
								className="btn btn-primary">
								Save
							</button>
						</Modal.Footer>
					</Modal>
				</Form>
			)}
		</div>
	);
};

export default PipelineEditor;
