import { Layout } from "antd";
import { Route, Routes, useParams } from "react-router-dom";
import Sidebar from "../../layout/sidebar";

import "./style.scss";
import DashboardHomePage from "./index.page";

import JobCreatePage from "./jobs/create.page";
import JobsListPage from "./jobs/list.page";
import Header from "../../components/header";
import { useQuery } from "@tanstack/react-query";
import request from "../../libs/request";
import SettingsHandler from "./settings/_handler";
import JobViewHandler from "./jobs/view/_handler";

import PipelinesListPage from "./pipelines/index.page";
import PipelineCreatePage from "./pipelines/create.page";
import PipelineEditPage from "./pipelines/update.page";

const DashboardHandler = () => {
	const { orgId } = useParams();

	const organizationDetail = useQuery({
		queryKey: ["dashboard", orgId],
		queryFn: async () => {
			const { data } = await request.get(`/orgs/${orgId}`);
			return data;
		},
	});

	return (
		<Layout className="dashboard-layout">
			<Header />
			<Layout.Content
				style={{ flex: 1, display: "flex", alignItems: "stretch" }}>
				<Sidebar />
				<Layout.Content>
					<Routes>
						<Route
							path="/"
							element={<DashboardHomePage />}
						/>
						<Route
							path="/jobs"
							element={<JobsListPage />}
						/>
						<Route
							path="/jobs/create"
							element={<JobCreatePage />}
						/>
						<Route
							path="/jobs/:jobId/*"
							element={<JobViewHandler />}
						/>

						<Route
							path="/pipelines"
							element={<PipelinesListPage />}
						/>
						<Route
							path="/pipelines/create"
							element={<PipelineCreatePage />}
						/>
						<Route
							path="/pipelines/edit/:pipelineId"
							element={<PipelineEditPage />}
						/>

						<Route
							path="/settings/*"
							element={<SettingsHandler />}
						/>
					</Routes>
				</Layout.Content>
			</Layout.Content>
		</Layout>
	);
};

export default DashboardHandler;
