import {
  Avatar,
  Button,
  Divider,
  Flex,
  Layout,
  Menu,
  Modal,
  Typography,
} from 'antd';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

import { useQueryClient } from '@tanstack/react-query';
import { Dropdown, List } from 'antd/lib';
import { useEffect, useState } from 'react';
import Configs from '../configs';

const Header = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { pathname } = useLocation();

  const [switchModalOpened, setSwitchModalOpened] = useState(false);

  const initData = queryClient.getQueryData(['init']);

  const onLogout = () => {
    localStorage.removeItem('jobapp.auth.token');
    queryClient.removeQueries(['init']);
    window.location.href = '/';
  };

  const onSwitch = (path) => {
    setSwitchModalOpened(false);
    navigate(path);
  };

  const endpoint = Configs.API_BASE_URL.replace('/v1', '/v2');

  useEffect(() => {
    if (initData) {
      return null;
    }
    const container = document.querySelector('.meraid-auth-container');
    if (!container.shadowRoot) {
      window.meraIDAuth({
        container: '.meraid-auth-container',
        buttonStyle: 'border-radius: 12px;width: max-content;height: 40px',
        authApi: {
          method: 'POST',
          url: `${endpoint}/auth/meraid-sign-in`,
        },
        collectApi: {
          method: 'POST',
          url: `${endpoint}/auth/meraid-collect`,
        },
        onSuccess: async ({ completionData, isNewUser, ...rest }) => {
          localStorage.setItem(
            'jobapp.auth.token',
            completionData.jwt.accessToken
          );
          if (
            completionData?.role === 'admin' ||
            completionData?.roles.includes('admin')
          ) {
            return (window.location.href = '/sudo/organizations');
          }

          if (isNewUser) window.location.href = '/account/profile';
          else window.location.href = '/';
        },
      });
    }
  }, [initData]);

  return (
    <Layout.Header
      style={{
        background: '#fff',
        justifyContent: 'space-between',
      }}
    >
      <span>JobApp</span>

      {(!pathname.startsWith('/dashboard/org') ||
        !pathname.startsWith('/dashboard/sudo')) && (
        <Menu
          style={{ justifyContent: 'center', flex: '1' }}
          onClick={({ key }) => navigate(key)}
          items={[
            {
              label: 'Find Jobs',
              key: '/jobs',
            },
            {
              label: 'Contact',
              key: '/contact',
            },
            {
              label: 'About Us',
              key: '/about',
            },
          ]}
          mode='horizontal'
        />
      )}
      {initData ? (
        <Dropdown
          menu={{
            items: [
              { label: 'Switch', onClick: () => setSwitchModalOpened(true) },
              { label: 'Logout', onClick: onLogout },
            ],
          }}
        >
          <Flex
            gap={10}
            align='center'
          >
            <span> {initData.fullName}</span>
            <Avatar />
          </Flex>
        </Dropdown>
      ) : (
        // <Button
        //   onClick={() => navigate("/auth/login")}
        //   type="default"
        //   shape="round"
        //   label="12312321"
        // >
        //   Login / Signup
        // </Button>

        <div className='meraid-auth-container' />
      )}
      <Modal
        className='switch-modal'
        open={switchModalOpened}
        footer={false}
        onCancel={() => switchModalOpened(false)}
        onClose={() => setSwitchModalOpened(false)}
      >
        <div class='switch-account-item'>
          <Typography.Title level={5}>Applicant</Typography.Title>
          <Button onClick={() => onSwitch(`/jobs`)}>Select</Button>
        </div>
        <Divider
          orientation='left'
          plain
        >
          Organizations
        </Divider>
        <List
          dataSource={initData?.orgs}
          renderItem={(item) => (
            <div class='switch-account-item'>
              <Typography.Title level={5}>{item.orgName}</Typography.Title>
              <Button onClick={() => onSwitch(`/dashboard/org-${item.orgId}`)}>
                Select
              </Button>
            </div>
          )}
        />
      </Modal>
    </Layout.Header>
  );
};

export default Header;
