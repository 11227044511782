import { PageHeader, PageLoading } from '@ant-design/pro-components';
import { useQuery } from '@tanstack/react-query';
import { Avatar, Button, Flex, Tag, Typography } from 'antd';
import {
  Route,
  Routes,
  useNavigate,
  useParams,
  useLocation,
} from 'react-router-dom';
import request from '../../../libs/request';
import JobApplySection from './apply.section';
import JobDetailSection from './detail.section';

const JobViewHandler = () => {
  const navigate = useNavigate();
  const { jobId } = useParams();
  const location = useLocation();

  const jobDetail = useQuery({
    queryKey: ['home', 'jobs', jobId],
    queryFn: async () => {
      const { data } = await request.get(`/home/jobs/${jobId}`);
      return data;
    },
  });

  if (jobDetail.isLoading) return <PageLoading />;

  const isApplyRoute = location.pathname.includes('apply');

  return (
    <>
      <PageHeader
        title={
          <Flex vertical>
            <h4>{jobDetail.data.jobTitle}</h4>
            <Flex gap={10}>
              <Avatar>MD</Avatar>
              <Flex vertical>
                <Typography.Text>Pixels Studios | Islamabad </Typography.Text>
                <Flex>
                  <Tag>Full-Time</Tag> <Tag>Islamabad</Tag>
                </Flex>
              </Flex>
            </Flex>
          </Flex>
        }
        onBack={() => navigate(-1)}
        extra={
          !isApplyRoute && (
            <Button
              type='primary'
              onClick={() => navigate(`/jobs/${jobId}/apply`)}
            >
              Apply Now
            </Button>
          )
        }
      />
      <Routes>
        <Route
          path=''
          index
          element={<JobDetailSection data={jobDetail?.data} />}
        />
        <Route
          path='apply'
          index
          element={<JobApplySection />}
        />
      </Routes>
    </>
  );
};

export default JobViewHandler;
